<template>
  <b-row>
    <b-col sm="12" xl="8">
      <c-card :title="`Ubah Pemasok '${formData.name}'`" :loading="cardloading">
        <template slot="action">
          <c-button :to="{name:'suppliers'}" variant="outline-primary">Kembali</c-button>
          <c-button v-if="!cardloading" :loading="isloading" @click="$refs.form.onSubmit()" type="submit">Simpan</c-button>
        </template>
        <c-form ref="form" @submit="confirmSubmit">
          <form-input label="Kode" v-model="formData.code" :rules="{required: true}"></form-input>
          <form-input label="Nama Lengkap" v-model="formData.name" :rules="{required: true}"></form-input>
          <form-textarea label="Alamat" v-model="formData.address"></form-textarea>
          <b-row cols-sm="1" cols-xl="2">
            <b-col>
              <form-select-all label="Kota" :rules="{required: true}" v-model="city" endpoint="v1/cities/select2"></form-select-all>
            </b-col>
            <b-col>
              <form-input label="Kode Pos" v-model="formData.zip_code"></form-input>
            </b-col>
          </b-row>
          <b-row cols-sm="1" cols-xl="2">
            <b-col>
              <form-input label="Telpon" v-model="formData.tlp"></form-input>
            </b-col>
            <b-col>
              <form-input label="Telpon 2" v-model="formData.tlp2"></form-input>
            </b-col>
          </b-row>
          <form-input label="Fax" v-model="formData.fax"></form-input>
          <form-input label="Email" v-model="formData.email" :rules="{email: true}"></form-input>
          <form-checkbox v-model="formData.is_active" label="Aktif"></form-checkbox>
          <button type="submit" hidden></button>
        </c-form>
      </c-card>
    </b-col>
  </b-row>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import FormInput from '@/utils/components/FormInput.vue'
import CForm from '@/utils/components/CForm.vue'
import CButton from '@/utils/components/CButton.vue'
import FormCheckbox from '@/utils/components/FormCheckbox.vue'
import FormSelectAll from '@/utils/components/FormSelectAll.vue'
import FormTextarea from '@/utils/components/FormTextarea.vue'

export default {
  components: { CCard, FormInput, CForm, CButton, FormCheckbox, FormSelectAll, FormTextarea },
  data(){
    const formData = {
      code:'',
      name:'',
      address:'',
      id_city:null,
      zip_code:'',
      tlp:'',
      tlp2:'',
      fax:'',
      email:'',
      is_active: true
    }
    return {
      formData,
      city: null,
      isloading: false,
      cardloading: true,
    }
  },
  methods:{
    confirmSubmit(){
      this.confirmation(`submitForm`)
    },
    submitForm(){
      const vm = this
      vm.isloading = true
      const formData = vm.formData
      if(vm.city) {
        formData.id_city = vm.city.value
      }
      formData.is_active = formData.is_active ? 1 : 0
      this.$http.put(`v1/contacts/update`,formData,{params:{id:vm.$route.params.id,name:formData.name}}).then(() => {
        vm.notify(`Data Berhasil Disimpan!`)
        vm.$router.push({name:'suppliers'})
        vm.isloading = false
      }).catch((error) => {
        vm.isloading = false
        vm.handleError(error)
      })
    },
    getData(){
      const vm = this
      vm.cardloading = true
      vm.$http.get(`v1/contacts/show`,{params:{id:vm.$route.params.id}}).then(({data}) => {
        vm.formData = {
          code: data.code,
          name: data.name,
          address: data.address,
          zip_code: data.zip_code,
          tlp: data.tlp,
          tlp2: data.tlp2,
          fax: data.fax,
          email: data.email,
          is_active: data.is_active ? true : false
        }
        vm.city = {value:data.id_city, label:data.city_name}
        vm.cardloading = false
      })
    }
  },
  created(){
    this.getData()
  }

}
</script>

<style>

</style>