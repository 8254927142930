<template>
  <validation-provider :name="label||placeholder" :rules="rules" v-slot="validationContext" :vid="vid">
    <b-form-group :label-cols-sm="labelCols" :description="description" :label="(label||'')+(isRequired&&label.length>0 ? ' *':'')" :label-for="id">
      <b-form-input ref="inputs" :name="id" :placeholder="placeholder" :id="id" :value="value" @input="handleInput" v-on="$listeners" v-bind="inputAttrs" :aria-describedby="`${id}-feedback`" :state="getValidationState(validationContext)"></b-form-input>
      <b-form-invalid-feedback :id="`${id}-feedback`">{{validationContext.errors[0]}}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import id from "vee-validate/dist/locale/id.json"
import { localize, extend, ValidationProvider } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";
import {BFormGroup,BFormInput,BFormInvalidFeedback} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import isUndefined from 'lodash/isUndefined'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('id', id)

export default {
  name:'FormInput',
  props:{
    label:{
      type:String,
      default:''
    },
    value:[String,Number],
    placeholder:{
      type:String,
      default:''
    },
    rules:Object,
    description:String,
    vid:String,
    labelCols:{
      type:Number,
      default:0
    }
  },
  components:{
    BFormGroup,
    BFormInput,
    ValidationProvider,
    BFormInvalidFeedback
  },
  data(){
    return {
      id:''
    }
  },
  computed:{
    inputAttrs(){
      const attrs = JSON.parse(JSON.stringify(this.$attrs))
      delete attrs.rules
      delete attrs.description
      delete attrs.vid
      return attrs
    },
    isRequired(){
      const rule = this.rules
      if(isUndefined(rule)||isUndefined(rule.required)) return false
      else return this.rules.required
    }
  },
  methods:{
    handleInput(e){
      this.$emit('input', e)
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? (valid ? null : false) : null;
    },
    focus(){
      this.$refs.inputs.focus()
    }
  },
  created(){
    this.id = uuidv4()
  }
}
</script>

<style lang="scss">
/*
  .form-control {
      background: transparent;
      border: none;
  }
  .form-control:focus {
     border-bottom: 1px solid #000000;
  }
*/
</style>